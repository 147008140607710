import React from 'react';
import { Link } from 'react-router-dom';
import HeaderPage from './HeaderPage'
import FooterPage from './FooterPage'

class MainPage extends React.Component{

  render() {
    return  (
      <div className="main">
      <HeaderPage title="GGDAPP" subtitle="PLAY,INVEST AND GET REWARDED!"/>  
      <div className="box">
        <Link className="outerBox mainBox1" to="/play">
          <div className="innerBox">
            <div className='box-frame-title'><span>Play</span></div>
          </div>
        </Link>
        <Link className="outerBox mainBox2" to="/invest">
          <div className="innerBox">
            <div className='box-frame-title'><span>Invest</span></div>
          </div>
        </Link>
        
      </div>
      <FooterPage/>  
      </div>
    );
  }
}

export default MainPage;
